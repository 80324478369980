import React, { Component } from 'react';
import { HashRouter as Router, Route } from 'react-router-dom'
import Login from './pages/Login'
import Register from './pages/Register'
import AppMenu from './components/Menu'
import Categorias from './pages/Categorias';
import CategoriasInterna from './pages/CategoriasInterna';
import Checklist from  './pages/Checklist';
import SplashScreen from './pages/Splashscreen';
import Profile from './pages/Profile';
import ChangeEmail from './pages/ChangeEmail';
import A2f from './pages/A2f';
import RecuperaSenha from './pages/RecuperaSenha';

class AppRouter extends Component {

  render() {
    return (

        <div className="Router">
        <Router>
        <Route exact path='/' component={SplashScreen} />
        <Route path='/login' component={Login} />
        <Route path='/cadastro' component={Register} />
        <Route path='/menu' component={AppMenu} />
        <Route path='/categorias' component={Categorias} />
        <Route path='/categoria/:id' component={CategoriasInterna} />
        <Route path='/checklist/:id' component={Checklist} />
        <Route path='/perfil' component={Profile} />
        <Route path='/altera-email/:email' component={ChangeEmail} />
        <Route path='/a2f' component={A2f} />
        <Route path='/recupera-senha' component={RecuperaSenha} />
        </Router>
      </div>
    );
  }
}
/*
<Route path='contacts' component={ContactList}/>
<Route path='contacts/:id' component={ContactDetail} />
<Route path='*' component={NotFound}/>
*/
export default AppRouter;