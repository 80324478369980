import React from 'react';
import { Link, Redirect } from 'react-router-dom'
import Services from "../Services";
import ArrowBack from '@material-ui/icons/ArrowBack';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { CssBaseline, Container, Typography, Button, Grid, Snackbar, ButtonGroup, TextField, Breadcrumbs, Box, Switch } from '@material-ui/core';
import { FormGroup, FormLabel, FormControlLabel } from '@material-ui/core';
import AppMenu from '../components/Menu'
import { Rating } from '@material-ui/lab';
import { createMuiTheme, ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MailIcon from '@material-ui/icons/Mail';
import MuiAlert from '@material-ui/lab/Alert';
import { If } from 'react-if';
import amber from '@material-ui/core/colors/amber';
import grey from '@material-ui/core/colors/grey';
import { MailOutlined } from '@material-ui/icons';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const StyledRating = withStyles({
    iconFilled: {
        color: '#f15927',
    },
    iconHover: {
        color: '#f15927',
    },
})(Rating);


const theme = createMuiTheme({
    palette: {
        primary: amber,
        secondary: grey,
        button: '#d2d5d8',
    },
});


class ChangeEmail extends React.Component {

    state = {
        codigo:'',
        open: false,
        redirect: '',
        loadingForm: false,
        modal_type: '',
    }

    componentDidMount() {
        document.getElementById("top").scroll(0, 0)
        let params = this.props.match.params;
        let usuario = JSON.parse(localStorage.getItem('id-user'));
        Services.alteraEmail(this, usuario, params.email)
    }

    responseAlteraEmail(response) {
        if (response.error) {
            this.setState({ error: response.msg });
            this.setState({ open_message: true })
            this.setState({ modal_type: 'error' })
        }
        else {
            this.setState({ error: "Código enviado via e-mail, insira no campo indicado para validar seu e-mail." });
            this.setState({ open_message: true })
            this.setState({ modal_type: 'success' })
        }
    }

    handleSubmit = e => {
        let usuario = JSON.parse(localStorage.getItem('id-user')) || '';
        this.setState({ loadingForm: true });
        e.preventDefault();
        // let token = JSON.parse(localStorage.getItem('token')) || '';
        let token = this.state.codigo
        Services.tokenValida(this, usuario, token);
    }

    responseTokenValida(response) {
        if (response.error) {
            this.setState({ loadingForm: false });
            this.setState({ error: response.msg });
            this.setState({ open_message: true })
            this.setState({ modal_type: 'error' })
        }
        else {
            this.setState({ error: "E-mail validado com sucesso!" });
            this.setState({ open_message_final: true })
            this.setState({ modal_type: 'success' })
        }
    }

    handleClose = () => {
        this.setState({ open_message: false })
    }

    handleCloseFinal = () => {
        this.setState({ open_message_final: false })
        if (this.state.modal_type === 'success'){
            this.setState({redirect: 'perfil'})
        }
    }

    handleInputChange = (event) => {
        if (event.target.name === 'codigo') {
            this.setState({ codigo: event.target.value })
            // console.log(this.state.codigo);
        }
    }

    alteraEmail = () => {
        this.setState({ visible_modal_altera_email: false })
        this.setState({ loadingModal: true })
        let email = this.state.email;
        this.setState({ redirect: '/altera-email/'+email})
    }

    handleCloseModalAlteraEmail = () => {
        this.setState({ visible_modal_altera_email: false })
    }


    render() {
        const usuario = JSON.parse(localStorage.getItem('user'));

        if (this.state.redirect) {
            return (<Redirect to={'/' + this.state.redirect} />);
        }
        return (
            <div id="top">
                <AppMenu />
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <main style={{ flexGrow: 1, height: '100vh', overflow: 'auto', marginTop: '-100vh', backgroundColor: '#ececea' }}>

                        <Grid container style={{ marginTop: '10vh' }}>
                            <Grid item xs={3}>
                                <ArrowBack style={{ color: '#666' }} onClick={() => this.setState({ redirect: 'perfil' })} />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography color="textPrimary">Alterar e-mail</Typography>
                            </Grid>
                        </Grid>

                        <Box mt={2}></Box>

                        <Container maxWidth="lg" >
                            <form onSubmit={this.handleSubmit}>
                                <Grid container spacing={2}>

                                    <Grid item xs={12} sm={12}>
                                        <Typography variant="body1" color="primary" align="left">
                                            Confirmação de E-mail:
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            name="codigo"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="codigo"
                                            type="tel"
                                            label="Código"
                                            onChange={this.handleInputChange}
                                            value={this.state.nome}
                                        />
                                    </Grid>

                                    <Grid item xs={12} lg={12}>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="secondary"
                                            disabled={this.state.loadingForm}
                                        >
                                            Confirmar e-mail
                                        </Button>
                                    </Grid>

                                    <Grid item xs={false} lg={3}></Grid>
                                </Grid>
                            </form>
                        </Container>

                        <Container maxWidth="lg">
                            <Snackbar open={this.state.open_message} autoHideDuration={5000} onClose={this.handleClose}>
                                <MuiAlert elevation={6} variant="filled" onClose={this.handleClose} severity={this.state.modal_type}>
                                    {this.state.error}
                                </MuiAlert>
                            </Snackbar>
                            <Snackbar open={this.state.open_message_final} autoHideDuration={5000} onClose={this.handleCloseFinal}>
                                <MuiAlert elevation={6} variant="filled" onClose={this.handleCloseFinal} severity={this.state.modal_type}>
                                    {this.state.error}
                                </MuiAlert>
                            </Snackbar>
                        </Container>

                    </main>

                </ThemeProvider>
            </div>
        )
    };
}


export default ChangeEmail;