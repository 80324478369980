import React from 'react';
import { Link, Redirect } from 'react-router-dom'
import Services from "../Services";
import ArrowBack from '@material-ui/icons/ArrowBack';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { CssBaseline, Container, Typography, Button, Grid, Snackbar, ButtonGroup, TextField, Breadcrumbs, Box, Switch } from '@material-ui/core';
import { FormGroup, FormLabel, FormControlLabel } from '@material-ui/core';
import AppMenu from '../components/Menu'
import { Rating } from '@material-ui/lab';
import { createMuiTheme, ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MailIcon from '@material-ui/icons/Mail';
import MuiAlert from '@material-ui/lab/Alert';
import { If } from 'react-if';
import amber from '@material-ui/core/colors/amber';
import grey from '@material-ui/core/colors/grey';
import { MailOutlined } from '@material-ui/icons';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const StyledRating = withStyles({
    iconFilled: {
        color: '#f15927',
    },
    iconHover: {
        color: '#f15927',
    },
})(Rating);


const theme = createMuiTheme({
    palette: {
        primary: amber,
        secondary: grey,
        button: '#d2d5d8',
    },
});


class Profile extends React.Component {

    state = {
        nome: '',
        email: '',
        email_aux: '',
        error: '',
        openmodal: false,
        modal_type: '',
        open: false,
        redirect: '',
        produtos: [],
        categorias: [],
        marcas: [],
        // paginas: 1,
        visible: false,
        alteracao: false,
        values: [],
        telefone: '',
        noprodutos: localStorage.getItem('noprodutos') || '',
        loadingModal: false,
        loadingForm: false,
        a2f: false,
        a2f_status: 'Inativa',
        visible_modal_altera_email: false,
        visible_modal_token_email: false,
    }

    componentDidMount() {
        document.getElementById("top").scroll(0, 0)
        let usuario = JSON.parse(localStorage.getItem('id-user'));
        Services.perfil(this, usuario)
    }

    responsePerfil(response) {
        if (response.error) {
            this.setState({ error: response.msg });
            this.setState({ open_message: true })
            this.setState({ modal_type: 'error' })
        }
        else {
            this.setState({ nome: response.user.nome })
            this.setState({ email_aux: response.user.email })
            if (response.user.status_2fa_email === 1 || response.user.status_2fa_email === '1') {
                this.setState({ a2f: true });
                this.setState({ a2f_status: 'Ativa' });
            }
            else {
                this.setState({ a2f: false });
            }
        }
    }

    handleSubmit = e => {
        let usuario = JSON.parse(localStorage.getItem('id-user')) || '';
        this.setState({ loadingForm: true });
        e.preventDefault();
        // let token = JSON.parse(localStorage.getItem('token')) || '';
        let values = {
            nome: this.state.nome,
            senha: this.state.senha,
            confirma_senha: this.state.confirmasenha,
        };
        Services.usuariosAtualiza(this, usuario, values);
    }

    handleClose = () => {
        this.setState({ open_message: false })
        if (this.state.modal_type === 'success') {
            window.location.reload();
        }
    }

    handleInputChange = (event) => {
        if (event.target.name === 'nome') {
            this.setState({ nome: event.target.value })
            // console.log(this.state.nome);
        }
        if (event.target.name === 'email') {
            this.setState({ email: event.target.value })
        }
        if (event.target.name === 'senha') {
            this.setState({ senha: event.target.value })
        }
        if (event.target.name === 'confirmasenha') {
            this.setState({ confirmasenha: event.target.value })
            if (this.state.confirmasenha !== this.state.senha) {
                this.setState({ helperSenha: "As senhas não correspondem" })
            }
            else {
                this.setState({ helperSenha: "" })
            }
        }
        if (event.target.name === 'a2f') {
            let usuario = JSON.parse(localStorage.getItem('id-user')) || '';
            this.setState({ a2f: event.target.checked })
            if (event.target.checked === false) {
                Services.usuarios2fa(this, usuario, 0);
            }
            else {
                Services.usuarios2fa(this, usuario, 1);
            }

        }
    }

    alteraEmail = () => {
        this.setState({ visible_modal_altera_email: false })
        this.setState({ loadingModal: true })
        let email = this.state.email;
        this.setState({ redirect: 'altera-email/' + email })
    }

    handleCloseModalAlteraEmail = () => {
        this.setState({ visible_modal_altera_email: false })
    }

    responseUsuariosAtualiza(response) {
        this.setState({ loadingForm: false });
        if (response.error) {
            this.setState({ error: response.msg });
            this.setState({ open_message: true })
            this.setState({ modal_type: 'error' })
        }
        else {
            this.setState({ error: "Perfil atualizado com sucesso!" });
            this.setState({ open_message: true })
            this.setState({ modal_type: 'success' })
        }
    }

    responseUsuarios2fa(response) {
        this.setState({ loadingForm: false });
        if (response.error) {
            this.setState({ error: response.msg });
            this.setState({ open_message: true })
            this.setState({ modal_type: 'error' })
        }
        else {
            this.setState({ error: "Autenticação de 2 fatores atualizada com sucesso!" });
            this.setState({ open_message: true })
            this.setState({ modal_type: 'success' })
        }
    }


    render() {
        const usuario = JSON.parse(localStorage.getItem('user'));

        if (this.state.redirect) {
            return (<Redirect to={'/' + this.state.redirect} />);
        }
        return (
            <div id="top">
                <AppMenu />
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <main style={{ flexGrow: 1, height: '100vh', overflow: 'auto', marginTop: '-100vh', backgroundColor: '#ececea' }}>

                        <Grid container style={{ marginTop: '10vh' }}>
                            <Grid item xs={3}>
                                <ArrowBack style={{ color: '#666' }} onClick={() => this.setState({ redirect: 'categorias' })} />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography color="textPrimary">Perfil</Typography>
                            </Grid>
                        </Grid>

                        <Box mt={2}></Box>

                        <Container maxWidth="lg" >
                            <form onSubmit={this.handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12}>
                                        <Typography variant="body1" color="primary" align="left">
                                            Preferências:
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={12} align="left">
                                        <FormGroup>
                                            <FormLabel component="legend" align="left">Autenticação de dois fatores via e-mail</FormLabel>
                                            <FormControlLabel
                                                control={<Switch name="a2f" color="primary" checked={this.state.a2f} onChange={this.handleInputChange} />}
                                                label={this.state.a2f_status}
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Typography variant="body1" color="primary" align="left">
                                            Informações básicas:
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            name="nome"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="nome"
                                            label="Nome"
                                            onChange={this.handleInputChange}
                                            value={this.state.nome}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="email_aux"
                                            label="E-mail"
                                            name="email_aux"
                                            value={this.state.email_aux}
                                            disabled
                                            onChange={this.handleInputChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography onClick={() => this.setState({ visible_modal_altera_email: true })} variant="body1" color="secondary" align="left" style={{ display: 'flex', alignItens: 'center' }}>
                                            <MailOutlined style={{ marginRight: 5 }} /> Quero alterar meu e-mail
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            name="senha"
                                            label="Senha"
                                            type="password"
                                            id="senha"
                                            value={this.state.senha}
                                            onChange={this.handleInputChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            name="confirmasenha"
                                            label="Confirmação de senha"
                                            type="password"
                                            id="confirmasenha"
                                            helperText="A senha deve conter no mínimo 6 caracteres."
                                            value={this.state.confirmasenha}
                                            onChange={this.handleInputChange}
                                        />
                                    </Grid>

                                    <Grid item xs={false} lg={3}></Grid>

                                    <Grid item xs={12} lg={12}>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="secondary"
                                            disabled={this.state.loadingForm}
                                        >
                                            Alterar informações básicas
                                        </Button>
                                    </Grid>

                                    <Grid item xs={false} lg={3}></Grid>

                                </Grid>
                            </form>
                        </Container>

                        <Box mt={2}></Box>

                        <Container maxWidth="lg">
                            <Snackbar open={this.state.open_message} autoHideDuration={5000} onClose={this.handleClose}>
                                <MuiAlert elevation={6} variant="filled" onClose={this.handleClose} severity={this.state.modal_type}>
                                    {this.state.error}
                                </MuiAlert>
                            </Snackbar>
                        </Container>

                        <Dialog open={this.state.visible_modal_altera_email} onClose={this.handleCloseModalAlteraEmail} aria-labelledby="form-dialog-title">

                            <DialogTitle id="form-dialog-title">Alteração de e-mail</DialogTitle>

                            <DialogContent>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="email"
                                    label="E-mail"
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.handleInputChange}
                                />
                            </DialogContent>

                            <DialogContent>
                                <Button
                                    color="primary"
                                    onClick={this.alteraEmail}
                                    disabled={this.state.loadingModal}>

                                    Receber token de confirmação
                                </Button>
                            </DialogContent>
                        </Dialog>

                    </main>

                </ThemeProvider>
            </div>
        )
    };
}


export default Profile;