import axios from 'axios';
var Services = {};
// var base_url = 'https://appbuffon.com.br/dev/ws/';
var base_url = 'https://appbuffon.com.br/ws/';

//user.cpf = '03112757025'
//user.senha = '240292'

// Services.login = (App, formData) => {
//     axios({
//         method: 'post',
//         url: 'https://appbuffon.com.br/ws/login',
//         data: formData,
//         headers: { 'Content-Type': 'multipart/form-data'}
//     })
//     .then(response => {
//         if (response.data.error === 'true')
//         {
//             App.erroUsuario(response.data)
//         }
//         else{
//             App.salvarUsuario(response.data.user)
//         }
//     })
//     .catch(error => {
//         alert(error);
//     });
// }

Services.login = (App, formData) => {
    axios({
        method: 'post',
        url: 'https://appbuffon.com.br/ws/login-v2',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data'}
    })
    .then(response => {
        if (response.data.error === 'true')
        {
            App.erroUsuario(response.data)
        }
        else{
            App.salvarUsuario(response.data.user)
        }
    })
    .catch(error => {
        alert("Verifique sua conexão com a internet. Se o problema persistir, entre em contato com o administrador. "+error);
    });
}

Services.cadastro = (App, values) => {
    axios.post(base_url + 'cadastro', {
        nome: values.nome, email: values.email, cpf_cnpj: values.cpf, senha: values.senha,
        senha_confirma: values.confirmasenha}).then(res => {
        if (res.data) {
            App.responseCadastro(res.data);
        }
    })
}

Services.assinatura = (App, formData) => {
    axios({
        method: 'post',
        url: 'https://appbuffon.com.br/ws/checklist/assinatura',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data'}
    })
    .then(response => {
        App.assinaturaResponse(response.data)
    })
    .catch(error => {
        alert("Verifique sua conexão com a internet. Se o problema persistir, entre em contato com o administrador. "+error);
    });
}

Services.categorias = (App, user) => {
    axios.post(base_url + 'checklists/categorias', { id_usuario: parseInt(user) }).then(res => {
        App.responseCategorias(res.data);
    })
}

Services.checklists = (App, user, categoria) => {
    axios.post(base_url + 'checklists', { id_usuario: parseInt(user), id_categoria: parseInt(categoria) }).then(res => {
        App.responseChecklists(res.data);
    })
}

Services.proximosChecklists = (App, user) => {
    axios.post(base_url + 'checklists/proximos', { id_usuario: parseInt(user.id), id_categoria: parseInt(user.id_categorias) }).then(res => {
        App.responseProximosChecklists(res.data);
    })
}

Services.checklistInfo = (App, user, checklist) => {
    axios.post(base_url + 'checklist', { id_usuario: parseInt(user), id_checklist: parseInt(checklist) }).then(res => {
        App.responseChecklistInfo(res.data);
    })
}

Services.comentarios = (App, user, checklist, tarefa, comentario, anexo) => {

    axios.post(base_url + 'checklist/comentarios-anexos', { id_usuario: parseInt(user.id), id_checklist: parseInt(checklist), id_pergunta: parseInt(tarefa), comentario: comentario, anexo: anexo }).then(res => {
        App.responseComentarios(res.data);
    })
}

Services.comentariosOffline = (App, user, checklist, tarefa, comentario, anexo) => {
    axios.post(base_url + 'checklist/comentarios-anexos', { id_usuario: parseInt(user), id_checklist: parseInt(checklist), id_pergunta: parseInt(tarefa), comentario: comentario, anexo: anexo }).then(res => {
    })
}

Services.resposta = (App, user, checklist, tarefa, resposta) => {

    axios.post(base_url + 'checklist/resposta', { id_usuario: parseInt(user), id_checklist: parseInt(checklist), id_pergunta: parseInt(tarefa), resposta: resposta }).then(res => {
        App.responseComentarios(res.data);
    })
}

Services.respostaOffline = (App, user, checklist, tarefa, resposta) => {

    axios.post(base_url + 'checklist/resposta', { id_usuario: parseInt(user), id_checklist: parseInt(checklist), id_pergunta: parseInt(tarefa), resposta: resposta }).then(res => {
        App.responseResposta(res.data);
    })
}

Services.finaliza = (App, user, checklist, assinatura) => {

    axios.post(base_url + 'checklist/finaliza', { id_usuario: parseInt(user), id_checklist: parseInt(checklist), id_assinatura: parseInt(assinatura) }).then(res => {
        App.responseFinaliza(res.data);
    })
}

Services.anexosVisualizar = (App, user, checklist) => {

    axios.post(base_url + 'checklist/comentarios-anexos-visualizar', { id_usuario: parseInt(user), id_checklist: parseInt(checklist)}).then(res => {
        App.responseAnexosVisualizar(res.data);
    })
}

Services.perfil = (App, user) => {
    axios.get(base_url + 'perfil?id_usuario='+parseInt(user)).then(res => {
        App.responsePerfil(res.data);
    });
}

Services.alteraEmail = (App, user, email) => {
    axios.post(base_url + 'emailtoken', { id_usuario: parseInt(user), email: email}).then(res => {
        App.responseAlteraEmail(res.data);
    })
}

Services.tokenValida = (App, user, token) => {
    axios.post(base_url + 'emailtoken/verifica', { id_usuario: parseInt(user), token: token}).then(res => {
        App.responseTokenValida(res.data);
    })
}

Services.usuariosAtualiza = (App, user, values) => {
    axios.post(base_url + 'usuarios/atualiza', { id_usuario: parseInt(user), nome: values.nome, senha: values.senha, senha_confirma: values.confirma_senha}).then(res => {
        App.responseUsuariosAtualiza(res.data);
    })
}

Services.autenticacaoToken = (App, user, email) => {
    axios.post(base_url + '2fa/token', { id_usuario: parseInt(user)}).then(res => {
        App.responseAutenticacaoToken(res.data);
    })
}

Services.autenticacaoTokenValida = (App, user, token) => {
    axios.post(base_url + '2fa/verifica', { id_usuario: parseInt(user), token: token}).then(res => {
        App.responseAutenticacaoTokenValida(res.data);
    })
}

Services.usuarios2fa = (App, user, status) => {
    axios.post(base_url + 'usuarios/2fa', { id_usuario: parseInt(user), status: parseInt(status)}).then(res => {
        App.responseUsuarios2fa(res.data);
    })
}

Services.recuperaSenha = (App, cpf) => {
    axios.get(base_url + 'recuperasenha?cpfcnpj='+ cpf).then(res => {
        App.responseRecuperaSenha(res.data);
    });
}


export default Services;