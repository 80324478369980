import React from 'react';
import { Link, Redirect } from 'react-router-dom'
import Services from "../Services";
import ArrowBack from '@material-ui/icons/ArrowBack';
import PngLogin from '../assets/logo-primary.png';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { CssBaseline, Container, Typography, Button, Grid, Snackbar, ButtonGroup, TextField, Breadcrumbs, Box, Switch } from '@material-ui/core';
import { FormGroup, FormLabel, FormControlLabel } from '@material-ui/core';
import AppMenu from '../components/Menu'
import { Rating } from '@material-ui/lab';
import { createMuiTheme, ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MailIcon from '@material-ui/icons/Mail';
import MuiAlert from '@material-ui/lab/Alert';
import { If } from 'react-if';
import amber from '@material-ui/core/colors/amber';
import grey from '@material-ui/core/colors/grey';
import { MailOutlined } from '@material-ui/icons';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const StyledRating = withStyles({
    iconFilled: {
        color: '#f15927',
    },
    iconHover: {
        color: '#f15927',
    },
})(Rating);


const theme = createMuiTheme({
    palette: {
        primary: amber,
        secondary: grey,
        button: '#d2d5d8',
    },
});


class RecuperaSenha extends React.Component {

    state = {
        cpf: '',
        open: false,
        redirect: '',
        loadingForm: false,
        modal_type: '',
    }

    componentDidMount() {
        document.getElementById("top").scroll(0, 0)
    }


    handleSubmit = e => {
        this.setState({ loadingForm: true });
        e.preventDefault();
        // let token = JSON.parse(localStorage.getItem('token')) || '';
        let cpf = this.state.cpf
        Services.recuperaSenha(this, cpf);
    }

    responseRecuperaSenha(response) {
        if (response.error) {
            this.setState({ error: response.msg });
            this.setState({ open_message: true })
            this.setState({ modal_type: 'error' })
        }
        else {
            this.setState({ error: "Recuperação de senha enviada para o e-mail cadastrado!" });
            this.setState({ open_message: true })
            this.setState({ modal_type: 'success' })
        }
    }


    handleClose = () => {
        this.setState({ open_message: false })
        if (this.state.modal_type === 'success') {
            this.setState({ redirect: 'login' })
        }
    }


    handleInputChange = (event) => {
        if (event.target.name === 'cpf') {
            this.setState({ cpf: event.target.value })
        }
    }


    render() {
        if (this.state.redirect) {
            return (<Redirect to={'/' + this.state.redirect} />);
        }
        return (<Container maxWidth="xs" id="top">
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <div className='login-page'>
                        <Grid container style={{ marginTop: '10vh' }}>
                            <Grid item xs={3}>
                                <Link to='/login' style={{ textDecoration: 'none' }} onClick={() => localStorage.clear()} >
                                    <ArrowBack style={{ color: '#666' }} />
                                </Link>
                            </Grid>
                            <Grid item xs={6}>
                                <img src={PngLogin} className="logo" alt="Logo Checklist" />
                                <Typography component="h2" variant="h5">
                                    CheckList
                                </Typography>
                            </Grid>
                        </Grid>

                        <Box mt={3}></Box>

                        <Container maxWidth="lg" >
                            <form onSubmit={this.handleSubmit}>
                                <Grid container spacing={2}>

                                    <Grid item xs={12} sm={12}>
                                        <Typography variant="body1" color="primary" align="center">
                                            Recuperação de senha:
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="cpf"
                                            label="CPF"
                                            name="cpf"
                                            type="tel"
                                            value={this.state.cpf}
                                            autoComplete="cpf"
                                            helperText="A recuperação de senha será enviada para o e-mail cadastrado."
                                            onChange={this.handleInputChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12} lg={12}>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="secondary"
                                            disabled={this.state.loadingForm}
                                        >
                                            Recuperar senha
                                        </Button>
                                    </Grid>

                                    <Grid item xs={false} lg={3}></Grid>
                                </Grid>
                            </form>
                        </Container>

                        <Container maxWidth="lg">
                            <Snackbar open={this.state.open_message} autoHideDuration={5000} onClose={this.handleClose}>
                                <MuiAlert elevation={6} variant="filled" onClose={this.handleClose} severity={this.state.modal_type}>
                                    {this.state.error}
                                </MuiAlert>
                            </Snackbar>
                        </Container>
                </div>
            </ThemeProvider>
        </Container>
        )
    };
}


export default RecuperaSenha;