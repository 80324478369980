import React from 'react';
import Services from "../Services";
import { Redirect, Link } from 'react-router-dom';
import PngLogin from '../assets/logo-primary.png';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import amber from '@material-ui/core/colors/amber';
import grey from '@material-ui/core/colors/grey';
import ReCAPTCHA from "react-google-recaptcha";
import { Box } from '@material-ui/core';

const TEST_SITE_KEY = "6LcJce8eAAAAAAsKF33o4STU58-L1R_scIWx2gns";

const theme = createMuiTheme({
    palette: {
        primary: amber,
        secondary: grey,
    },
});


export default class SignIn extends React.Component {

    state = {
        cpf: '',
        senha: '',
        redirect: '',
        error: '',
        open_message: false,
        valueCaptcha: "[empty]",
    }

    componentDidMount() {

    }

    handleSubmit = e => {
        e.preventDefault();
        let formData = new FormData();    //formdata object

        if (this.state.valueCaptcha === null || this.state.valueCaptcha === "[empty]") {
            this.setState({ error: 'Ops! Você deve confirmar que não é um robô!' });
            this.setState({ open_message: true })
            return
        }
        let grecaptcha = this.state.valueCaptcha;
        formData.append('login', this.state.cpf);   //append the values with key, value pair
        formData.append('password', this.state.senha);
        formData.append('g-recaptcha-response', grecaptcha)
        //this.setState({ redirect: 'menu' })
        // const config = {
        //     headers: { 'content-type': 'multipart/form-data' }
        // }
        Services.login(this, formData);
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    salvarUsuario(usuario) {
        localStorage.setItem('nome-user', JSON.stringify(usuario.nome));
        localStorage.setItem('id-user', JSON.stringify(usuario.id));
        Services.perfil(this, usuario.id)
    }

    responsePerfil(response) {
        if (response.error) {
            this.setState({ error: response.msg });
            this.setState({ open_message: true })
            this.setState({ modal_type: 'error' })
        }
        else {
            // localStorage.setItem('user', JSON.parse(response.user));
            if (response.user.status_2fa_email === 1 || response.user.status_2fa_email === '1') {
                this.setState({ redirect: 'a2f' })
            }
            else {
                let id = JSON.parse(localStorage.getItem('id-user'));
                Services.categorias(this, id);
            }
        }
    }

    erroUsuario(response) {
        this.setState({ error: response.msg });
        this.setState({ open_message: true })
        this.setState({ valueCaptcha: '[empty]' })
        this.captcha.reset();
    }

    responseCategorias(response) { // Salva todas as categorias no cache no momento do login
        let id = JSON.parse(localStorage.getItem('id-user'));
        // console.log(response.categorias)

        if (response.categorias.length === 0) {
            this.setState({ redirect: 'categorias' });
        }

        else if (response.categorias.length !== 0) {
            localStorage.removeItem('categorias');
            localStorage.setItem('categorias', JSON.stringify(response.categorias)); //salva as categorias
            let categorias = response.categorias;
            for (let i = 0; i < parseInt(categorias.length); i++) { // Array de categorias, percorre chamando os checklists, envia o id da categoria
                let parametro = categorias[i].id;
                Services.checklists(this, id, parametro);
            }
        }
    }

    responseChecklists(response) { //Resposta dos checklists
        if (response.success === 'true') {
            let array = response.checklists;

            if (array.length === 0) {
                this.setState({ redirect: 'categorias' });
            }
            else {
                for (let i = 0; i < parseInt(array.length); i++) { //Array que percorre os checklists, e chama o serviço de informações internas de cada checklist
                    let parametro = array[i].id;
                    let parametroCateg = array[i].id_categoria;
                    let conteudo = array[i];
                    let id = JSON.parse(localStorage.getItem('id-user'));

                    if (JSON.parse(localStorage.getItem('checklists-' + parametroCateg)) !== []) {
                        let storage = JSON.parse(localStorage.getItem('checklists-' + parametroCateg)) || [];
                        storage.push(conteudo);
                        localStorage.setItem('checklists-' + parametroCateg, JSON.stringify(storage)); // Salva cada checklist no local storage referenciado pelo seu array
                    }

                    else {
                        localStorage.setItem('checklists-' + parametroCateg, JSON.stringify(conteudo)); // Salva cada checklist no local storage referenciado pelo seu array
                    }

                    Services.checklistInfo(this, id, parametro);
                }
            }
        }

        else {
            this.setState({ error: 'Erro ao salvar seus dados para acesso offline' });
            this.setState({ open_message: true })
        }

    }

    responseChecklistInfo(response) {
        if (response.checklist.length > 0) {
            localStorage.setItem('checklistInfos-' + response.checklist.info.id, JSON.stringify(response));
            this.setState({ redirect: 'categorias' });
        }
        else {
            this.setState({ redirect: 'categorias' });
        }
    }

    handleClose = () => {
        this.setState({ open_message: false })
    }

    handleChangeRecaptcha = value => {
        // console.log("Captcha value:", value);
        this.setState({ valueCaptcha: value });
        // if value is null recaptcha expired
        if (value === null) this.setState({ expired: "true" });
    };

    asyncScriptOnLoad = () => {
        this.setState({ callback: "called!" });
        // console.log("scriptLoad - reCaptcha Ref-", this._reCaptchaRef);
    };

    render() {

        if (this.state.redirect) {
            return (<Redirect to={'/' + this.state.redirect} />);
        }
        return (
            <Container maxWidth="xs">
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <div className='login-page'>

                        <img src={PngLogin} className="logo" alt="Logo Checklist" />

                        <Typography component="h2" variant="h5">
                            CheckList
                        </Typography>
                        <Typography component="h5">
                            Faça login em sua conta para acessar as tarefas e checklists.
                        </Typography>
                        <form>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="CPF"
                                name="cpf"
                                type="tel"
                                value={this.state.cpf}
                                autoComplete="cpf"

                                onChange={e => this.handleChange(e)}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="senha"
                                value={this.state.senha}
                                label="Senha"
                                type="password"
                                id="senha"
                                autoComplete="current-password"
                                onChange={e => this.handleChange(e)}
                            />

                            <ReCAPTCHA
                                style={{ display: "inline-block" }}
                                ref={(r) => this.captcha = r}
                                // ref={this._reCaptchaRef}
                                sitekey={TEST_SITE_KEY}
                                onChange={this.handleChangeRecaptcha}
                                asyncScriptOnLoad={this.asyncScriptOnLoad}
                            />

                            <Button
                                onClick={e => this.handleSubmit(e)}
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                style={{ marginTop: 20 }}
                            >
                                Entrar
                            </Button>

                        </form>

                        {/* <a href='https://appbuffon.com.br/cadastro/' style={{ textDecoration: 'none' }}> */}
                        <Link to="/cadastro"><Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            style={{ marginTop: 20, textDecoration: 'none' }}
                        >
                            Cadastre-se
                        </Button>
                        </Link>
                        <Box mt={2}>
                        <Link to="/recupera-senha">Esqueceu sua senha?</Link>
                        </Box>
                        {/* </a> */}

                    </div>
                </ThemeProvider>

                <Snackbar open={this.state.open_message} autoHideDuration={5000} onClose={this.handleClose}>
                    <MuiAlert elevation={6} variant="filled" onClose={this.handleClose} severity="error">
                        {this.state.error}
                    </MuiAlert>
                </Snackbar>

            </Container>
        );
    }

}