import React from 'react';
import { Link, Redirect } from 'react-router-dom'
import Services from "../Services";
import ArrowBack from '@material-ui/icons/ArrowBack';
import PngLogin from '../assets/logo-primary.png';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { CssBaseline, Container, Typography, Button, Grid, Snackbar, ButtonGroup, TextField, Breadcrumbs, Box, Switch } from '@material-ui/core';
import { FormGroup, FormLabel, FormControlLabel } from '@material-ui/core';
import AppMenu from '../components/Menu'
import { Rating } from '@material-ui/lab';
import { createMuiTheme, ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MailIcon from '@material-ui/icons/Mail';
import MuiAlert from '@material-ui/lab/Alert';
import { If } from 'react-if';
import amber from '@material-ui/core/colors/amber';
import grey from '@material-ui/core/colors/grey';
import { MailOutlined } from '@material-ui/icons';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const StyledRating = withStyles({
    iconFilled: {
        color: '#f15927',
    },
    iconHover: {
        color: '#f15927',
    },
})(Rating);


const theme = createMuiTheme({
    palette: {
        primary: amber,
        secondary: grey,
        button: '#d2d5d8',
    },
});


class A2f extends React.Component {

    state = {
        codigo: '',
        open: false,
        redirect: '',
        loadingForm: false,
        modal_type: '',
    }

    componentDidMount() {
        document.getElementById("top").scroll(0, 0)
        let usuario = JSON.parse(localStorage.getItem('id-user'));
        Services.autenticacaoToken(this, usuario)
    }

    responseAutenticacaoToken(response) {
        if (response.error) {
            this.setState({ error: response.msg });
            this.setState({ open_message: true })
            this.setState({ modal_type: 'error' })
        }
        else {
            this.setState({ error: "Código enviado via e-mail, insira no campo indicado para validar seu login." });
            this.setState({ open_message: true })
            this.setState({ modal_type: 'success' })
        }
    }

    handleSubmit = e => {
        let usuario = JSON.parse(localStorage.getItem('id-user')) || '';
        this.setState({ loadingForm: true });
        e.preventDefault();
        // let token = JSON.parse(localStorage.getItem('token')) || '';
        let token = this.state.codigo
        Services.autenticacaoTokenValida(this, usuario, token);
    }

    responseAutenticacaoTokenValida(response) {
        if (response.error) {
            this.setState({ loadingForm: false });
            this.setState({ error: response.msg });
            this.setState({ open_message: true })
            this.setState({ modal_type: 'error' })
        }
        else {
            let id = JSON.parse(localStorage.getItem('id-user')) || '';
            Services.categorias(this, id);
        }
    }

    handleClose = () => {
        this.setState({ open_message: false })
    }


    handleInputChange = (event) => {
        if (event.target.name === 'codigo') {
            this.setState({ codigo: event.target.value })
            // console.log(this.state.codigo);
        }
    }

    responseCategorias(response) { // Salva todas as categorias no cache no momento do login
        let id = JSON.parse(localStorage.getItem('id-user'));
        // console.log(response.categorias)

        if (response.categorias.length === 0) {
            this.setState({ redirect: 'categorias' });
        }

        else if (response.categorias.length !== 0) {
            localStorage.removeItem('categorias');
            localStorage.setItem('categorias', JSON.stringify(response.categorias)); //salva as categorias
            let categorias = response.categorias;
            for (let i = 0; i < parseInt(categorias.length); i++) { // Array de categorias, percorre chamando os checklists, envia o id da categoria
                let parametro = categorias[i].id;
                Services.checklists(this, id, parametro);
            }
        }
    }

    responseChecklists(response) { //Resposta dos checklists
        if (response.success === 'true') {
            let array = response.checklists;

            if (array.length === 0) {
                this.setState({ redirect: 'categorias' });
            }
            else {
                for (let i = 0; i < parseInt(array.length); i++) { //Array que percorre os checklists, e chama o serviço de informações internas de cada checklist
                    let parametro = array[i].id;
                    let parametroCateg = array[i].id_categoria;
                    let conteudo = array[i];
                    let id = JSON.parse(localStorage.getItem('id-user'));

                    if (JSON.parse(localStorage.getItem('checklists-' + parametroCateg)) !== []) {
                        let storage = JSON.parse(localStorage.getItem('checklists-' + parametroCateg)) || [];
                        storage.push(conteudo);
                        localStorage.setItem('checklists-' + parametroCateg, JSON.stringify(storage)); // Salva cada checklist no local storage referenciado pelo seu array
                    }

                    else {
                        localStorage.setItem('checklists-' + parametroCateg, JSON.stringify(conteudo)); // Salva cada checklist no local storage referenciado pelo seu array
                    }

                    Services.checklistInfo(this, id, parametro);
                }
            }
        }

        else {
            this.setState({ error: 'Erro ao salvar seus dados para acesso offline' });
            this.setState({ open_message: true })
        }

    }

    responseChecklistInfo(response) {
        if (response.checklist.length > 0) {
            localStorage.setItem('checklistInfos-' + response.checklist.info.id, JSON.stringify(response));
            this.setState({ redirect: 'categorias' });
        }
        else {
            this.setState({ redirect: 'categorias' });
        }
    }


    render() {
        const usuario = JSON.parse(localStorage.getItem('user'));

        if (this.state.redirect) {
            return (<Redirect to={'/' + this.state.redirect} />);
        }
        return (<Container maxWidth="xs" id="top">
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <div className='login-page'>

                    <Grid container style={{ marginTop: '10vh' }}>
                        <Grid item xs={3}>
                            <Link to='/login' style={{ textDecoration: 'none' }} onClick={() => localStorage.clear()} >
                                <ArrowBack style={{ color: '#666' }} />
                            </Link>
                        </Grid>
                        <Grid item xs={6}>
                            <img src={PngLogin} className="logo" alt="Logo Checklist" />
                            <Typography component="h2" variant="h5">
                                CheckList
                            </Typography>
                        </Grid>
                    </Grid>

                    <Box mt={3}></Box>

                    <Container maxWidth="lg" >
                        <form onSubmit={this.handleSubmit}>
                            <Grid container spacing={2}>

                                <Grid item xs={12} sm={12}>
                                    <Typography variant="body1" color="primary" align="center">
                                        Autenticação de 2 fatores:
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        name="codigo"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="codigo"
                                        type="tel"
                                        label="Código"
                                        onChange={this.handleInputChange}
                                        value={this.state.nome}
                                        helperText="O código pode demorar alguns minutos para ser enviado ao seu e-mail. Revise sua caixa de spam."
                                    />
                                </Grid>

                                <Grid item xs={12} lg={12}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="secondary"
                                        disabled={this.state.loadingForm}
                                    >
                                        Autenticar login
                                    </Button>
                                </Grid>

                                <Grid item xs={false} lg={3}></Grid>
                            </Grid>
                        </form>
                    </Container>

                    <Container maxWidth="lg">
                        <Snackbar open={this.state.open_message} autoHideDuration={5000} onClose={this.handleClose}>
                            <MuiAlert elevation={6} variant="filled" onClose={this.handleClose} severity={this.state.modal_type}>
                                {this.state.error}
                            </MuiAlert>
                        </Snackbar>
                    </Container>

                </div>

            </ThemeProvider>
        </Container>
        )
    };
}


export default A2f;