import React from 'react';
import Services from "../Services";
import { Redirect, Link } from 'react-router-dom';
import PngLogin from '../assets/logo-primary.png';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import amber from '@material-ui/core/colors/amber';
import grey from '@material-ui/core/colors/grey';
import If from 'react-if';

const theme = createMuiTheme({
    palette: {
        primary: amber,
        secondary: grey,
    },
});


export default class Register extends React.Component {

    state = {
        cpf: '',
        senha: '',
        redirect: '',
        error: '',
        open_message: false,
        checkButton: false,
        nome: '',
        senha: '',
        email: '',
        confirmasenha: '',
        modal_type: '',
    }

    componentDidMount() {

    }

    handleInputChange = (event) => {
        if (event.target.name === 'nome') {
            this.setState({ nome: event.target.value })
            console.log(this.state.nome);
        }
        else if (event.target.name === 'cpf') {
            this.setState({ cpf: event.target.value })
        }
        else if (event.target.name === 'email') {
            this.setState({ email: event.target.value })
        }
        else if (event.target.name === 'senha') {
            this.setState({ senha: event.target.value })
        }
        else if (event.target.name === 'confirmasenha') {
            this.setState({ confirmasenha: event.target.value })
        }
        else if (event.target.name === 'termos') {
            if (this.state.checkButton === false) {
                this.setState({ checkButton: true })
            }
            else {
                this.setState({ checkButton: false })
            }
        }
    }

    handleSubmit = e => {
        this.setState({ loadingForm: true })
        e.preventDefault();

        if (this.state.checkButton === false) {
            this.setState({ error: "Confirme que você leu e aceita os nossos termos de utilização." });
            this.setState({ open_message: true })
            this.setState({ modal_type: 'warning' })
            return
        }

        let values = [{
            nome: this.state.nome,
            cpf: this.state.cpf,
            email: this.state.email,
            senha: this.state.senha,
            confirmasenha: this.state.confirmasenha,
        }];

        Services.cadastro(this, values[0]);
    }


    responseCadastro(response) {
        this.setState({ loadingForm: false })
        if (response.error === 'true') {
            this.setState({ error: response.msg });
            this.setState({ open_message: true })
            this.setState({ modal_type: 'error' })
            return
        }
        else {
            this.setState({ error: "Cadastro efetuado com sucesso!" });
            this.setState({ open_message: true })
            this.setState({ modal_type: 'success' })
        }
    }

    salvarUsuario(usuario) {
        localStorage.setItem('nome-user', JSON.stringify(usuario.nome));
        localStorage.setItem('id-user', JSON.stringify(usuario.id));
        let id = JSON.parse(localStorage.getItem('id-user'));
        Services.categorias(this, id); //salva o usuario e chama o serviço de categorias para salvar no cache
        //this.setState({ redirect: 'categorias' })
    }

    erroUsuario(response) {
        this.setState({ error: response.msg });
        this.setState({ open_message: true })
    }

    responseCategorias(response) { // Salva todas as categorias no cache no momento do login
        let id = JSON.parse(localStorage.getItem('id-user'));
        console.log(response.categorias)

        if (response.categorias.length === 0) {
            this.setState({ redirect: 'categorias' });
        }

        else if (response.categorias.length !== 0) {
            localStorage.removeItem('categorias');
            localStorage.setItem('categorias', JSON.stringify(response.categorias)); //salva as categorias
            let categorias = response.categorias;
            for (let i = 0; i < parseInt(categorias.length); i++) { // Array de categorias, percorre chamando os checklists, envia o id da categoria
                let parametro = categorias[i].id;
                Services.checklists(this, id, parametro);
            }
        }
    }


    handleClose = () => {
        this.setState({ open_message: false })
        if(this.state.modal_type === 'success'){
            this.setState({redirect: 'login'})
        }
    }

    responseChecklists(response) { //Resposta dos checklists
        if (response.success === 'true') {
            let array = response.checklists;

            if (array.length === 0) {
                this.setState({ redirect: 'categorias' });
            }
            else {
                for (let i = 0; i < parseInt(array.length); i++) { //Array que percorre os checklists, e chama o serviço de informações internas de cada checklist
                    let parametro = array[i].id;
                    let parametroCateg = array[i].id_categoria;
                    let conteudo = array[i];
                    let id = JSON.parse(localStorage.getItem('id-user'));

                    if (JSON.parse(localStorage.getItem('checklists-' + parametroCateg)) !== []) {
                        let storage = JSON.parse(localStorage.getItem('checklists-' + parametroCateg)) || [];
                        storage.push(conteudo);
                        localStorage.setItem('checklists-' + parametroCateg, JSON.stringify(storage)); // Salva cada checklist no local storage referenciado pelo seu array
                    }

                    else {
                        localStorage.setItem('checklists-' + parametroCateg, JSON.stringify(conteudo)); // Salva cada checklist no local storage referenciado pelo seu array
                    }

                    Services.checklistInfo(this, id, parametro);
                }
            }
        }

        else {
            this.setState({ error: 'Erro ao salvar seus dados para acesso offline' });
            this.setState({ open_message: true })
        }

    }

    responseChecklistInfo(response) {
        if (response.checklist.length > 0) {
            localStorage.setItem('checklistInfos-' + response.checklist.info.id, JSON.stringify(response));
            this.setState({ redirect: 'categorias' });
        }
        else {
            this.setState({ redirect: 'categorias' });
        }
    }

    render() {

        if (this.state.redirect) {
            return (<Redirect to={'/' + this.state.redirect} />);
        }
        return (
            <Container maxWidth="xs">
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <div className='login-page'>

                        <img src={PngLogin} className="logo" />

                        <Typography component="h2" variant="h5">
                            CheckList
                        </Typography>
                        <Typography component="h5">
                            Realize seu cadastro através do formulário abaixo
                        </Typography>
                        <form onSubmit={this.handleSubmit}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="cpf"
                                label="CPF ou CNPJ"
                                name="cpf"
                                type="tel"
                                value={this.state.cpf}

                                onChange={this.handleInputChange}
                            />

                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="nome"
                                label="Nome ou Nome Fantasia"
                                name="nome"
                                type="text"
                                value={this.state.nome}

                                onChange={this.handleInputChange}
                            />

                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="E-mail"
                                name="email"
                                type="email"
                                value={this.state.email}

                                onChange={this.handleInputChange}
                            />

                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="senha"
                                value={this.state.senha}
                                label="Senha"
                                type="password"
                                id="senha"
                                onChange={this.handleInputChange}
                            />

                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="confirmasenha"
                                value={this.state.confirmasenha}
                                label="Confirmação de senha"
                                type="password"
                                id="confirmasenha"
                                onChange={this.handleInputChange}
                            />


                            <FormControlLabel
                                control={<Checkbox checked={this.state.checkButton} name="termos" onChange={this.handleInputChange} color="primary" />}
                                label="Declaro que li os Termos de Uso e CONCORDO."
                            />
                            <a href="https://appbuffon.com.br/politica-privacidade.html" target="_blank" style={{ color: '#000' }}>Ler os Termos de Uso</a>

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                style={{ marginTop: 20 }}
                            >
                                Cadastrar
                            </Button>
                        </form>


                        <Link to='/login'>
                            <Button
                                fullWidth
                                variant="contained"
                                color="secondary"
                                style={{ marginTop: 20, textDecoration: 'none' }}
                            >
                                Voltar para o login
                            </Button></Link>


                    </div>
                </ThemeProvider>

                <Snackbar open={this.state.open_message} autoHideDuration={5000} onClose={this.handleClose}>
                    <MuiAlert elevation={6} variant="filled" onClose={this.handleClose} severity={this.state.modal_type}>
                        {this.state.error}
                    </MuiAlert>
                </Snackbar>

            </Container>
        );
    }

}