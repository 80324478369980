import React from 'react';
import Services from "../Services";
import { Link } from 'react-router-dom'
import AppMenu from '../components/Menu'
import { Redirect } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import RefreshRounded from '@material-ui/icons/RestorePageRounded';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import amber from '@material-ui/core/colors/amber';
import grey from '@material-ui/core/colors/grey';
import If from 'react-if';

const theme = createMuiTheme({
    palette: {
        primary: amber,
        secondary: grey,
    },
});

export default class CategoriasInterna extends React.Component {

    state = {
        cpf: '',
        senha: '',
        redirect: '',
        error: '',
        open_message: false,
        checklists: [],
        proximos: [],
        divider: '',
    }

    componentDidMount() {

        if (navigator.appCodeName !== 'Mozilla') {
            var networkState = navigator.connection.type;
        }
        else {
            var networkState = '';
        }

        var params = this.props.match.params;
        let user = JSON.parse(localStorage.getItem('nome')); //limpando o nome do usuario salvo na cache
        let id = JSON.parse(localStorage.getItem('id-user')); //limpando o id do usuario salvo na cache
        let check = JSON.parse(localStorage.getItem('checklists-' + params.id)) || [];

        if (navigator.onLine || networkState !== 'none') {
            Services.checklists(this, id, params.id);
            Services.proximosChecklists(this, { id: id, id_categorias: params.id });
        }

        if (!navigator.onLine || networkState === 'none') {
            const array = JSON.parse(localStorage.getItem('checklists-' + params.id));
            this.setState({ checklists: array })
        }

        if (networkState === 'none' && check === [] || !navigator.onLine && check === []) {
            this.setState({ error: "Você não está conectado a internet" });
            this.setState({ open_message: true })
            this.setState({ modal_type: 'error' })
        }

    }

    responseChecklists(response) {
        this.setState({ checklists: response.checklists });
        window.localStorage.removeItem('checklists');
        localStorage.setItem('checklists', JSON.stringify(response.checklists));
        if (this.state.checklists === [] || this.state.checklists[0] === [] || this.state.checklists[0] === '' || this.state.checklists[0] === undefined || this.state.checklists[0] === null) {
            this.setState({ error: "Não há checklists cadastrados no momento" });
            this.setState({ open_message: true })
            this.setState({ modal_type: 'warning' })
        }
    }

    responseProximosChecklists(response) {
        this.setState({ proximos: response.checklists });
    }

    handleClose = () => {
        this.setState({ open_message: false })
    }

    render() {
        if (this.state.redirect) {
            return (<Redirect to={'/' + this.state.redirect} />);
        }
        return (<div>
            <AppMenu />

            <main style={{ flexGrow: 1, height: '100vh', overflow: 'auto', marginTop: '-100vh', backgroundColor: '#ececea' }}>
                <ThemeProvider theme={theme}>
                    <Grid container style={{ marginTop: '10vh' }}>
                        <Grid item xs={3}>
                            <ArrowBack style={{ color: '#666' }} onClick={() => this.setState({ redirect: 'categorias' })} />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography color="textPrimary">Categorias</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <RefreshRounded style={{ color: '#666' }} onClick={() => window.location.reload()} />
                        </Grid>
                    </Grid>

                    <List component="nav" aria-label="contacts">
                        {this.state.checklists.map((checklists, index) => {
                            return <If condition={index > 0} key={checklists.id}><Link to={"/checklist/" + checklists.id} className='link' >
                                <Grid container spacing={1} style={{ background: '#fff', width: '96%', marginLeft: '2%', marginBottom: 10 }}>
                                    <Grid item xs={2}>
                                        <span className="tag-categoria" style={{ background: checklists.categoria_cor }} >
                                            {checklists.categoria}
                                        </span>
                                    </Grid>
                                    <Grid item xs={10} />
                                    <Grid item xs={12}>
                                        <label className="title-checklist">{checklists.titulo}</label>
                                        <If condition={checklists.posto != ''}>
                                            <p className='prazo'>Posto: {checklists.posto}</p>
                                        </If>
                                    </Grid>
                                    <Grid item xs={2}><PlaylistAddCheckIcon style={{ color: '#888' }} /></Grid>
                                    <Grid item xs={10} style={{ marginLeft: 0 }}>
                                        <label className='prazo'>
                                            Prazo para envio {checklists.data_hora_ini} - {checklists.data_hora_fim}
                                        </label>
                                    </Grid>
                                </Grid>
                            </Link></If>
                        })}
                    </List>

                    <If condition={this.state.proximos.length > 0}><div>
                        <Typography color="textPrimary">Próximos Checklists</Typography>

                        <List component="nav" aria-label="contacts">
                            {this.state.proximos.map((proximos) => {
                                return <Grid container spacing={1} style={{ background: '#fff', width: '96%', marginLeft: '2%', marginBottom: 10 }}>
                                    <Grid item xs={2}>
                                        <span className="tag-categoria" style={{ background: proximos.categoria_cor }} >
                                            {proximos.categoria}
                                        </span>
                                    </Grid>
                                    <Grid item xs={10} />
                                    <Grid item xs={12}>
                                        <label className="title-checklist">{proximos.titulo}</label>
                                        <If condition={proximos.posto != ''}>
                                            <p className='prazo'>Posto: {proximos.posto}</p>
                                        </If>
                                    </Grid>
                                    <Grid item xs={2}><PlaylistAddCheckIcon style={{ color: '#888' }} /></Grid>
                                    <Grid item xs={10} style={{ marginLeft: 0 }}>
                                        <label className='prazo'>
                                            Prazo para envio {proximos.data_hora_ini} - {proximos.data_hora_fim}
                                        </label>
                                    </Grid>
                                </Grid>
                            })}
                        </List>
                    </div>
                    </If>

                    <Snackbar open={this.state.open_message} autoHideDuration={5000} onClose={this.handleClose}>
                        <MuiAlert elevation={6} variant="filled" onClose={this.handleClose} severity={this.state.modal_type}>
                            {this.state.error}
                        </MuiAlert>
                    </Snackbar>

                </ThemeProvider>
            </main>

        </div>);
    }

}