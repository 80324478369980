import React from 'react';
import Services from "../Services";
import AppMenu from '../components/Menu'
import { Redirect } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/AddAPhoto';
import SaveIcon from '@material-ui/icons/AddPhotoAlternate';
import ArrowBack from '@material-ui/icons/ArrowBack';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/PriorityHigh';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Input from '@material-ui/core/Input'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import amber from '@material-ui/core/colors/amber';
import grey from '@material-ui/core/colors/grey';
import Alert from '@material-ui/lab/Alert';
import Resizer from 'react-image-file-resizer';
import If from 'react-if';

const theme = createMuiTheme({
    palette: {
        primary: amber,
        secondary: grey,
        button: '#d2d5d8',
    },
});


const resizeFile = (file) => new Promise(resolve => {
    Resizer.imageFileResizer(file, 1000, 1000, 'JPEG', 60, 0,
        uri => {
            resolve(uri);
        }, 'base64');
});

export default class Checklist extends React.Component {

    state = {
        cpf: '',
        senha: '',
        redirect: '',
        error: '',
        open_message: false,
        openmodal: false,
        checklist: [],
        checklistInfo: [],
        checklistTarefas: [],
        anexosArray: [],
        opcoes: [],
        anexo: '',
        size: '',
        resposta: '',
        comentario: '',
        count: '',
        comentarioObrigatorio: false,
        comentarioObrigatorio: false,
        fotoObrigatorio: false,
        endButton: 'next',
        checkboxList: [],
        loading: false,
        loadingfin: false,
    }

    componentDidMount() {
        if (navigator.appCodeName !== 'Mozilla') {
            var networkState = navigator.connection.type;
        }
        else {
            var networkState = '';
        }

        var params = this.props.match.params;

        let id = JSON.parse(localStorage.getItem('id-user'));

        const checklistInfo = JSON.parse(localStorage.getItem('checklistInfos-' + params.id));

        const contadorRespostas = JSON.parse(localStorage.getItem('respostas-' + params.id)) || [];
        this.setState({ count: parseInt(contadorRespostas.length) })

        if (navigator.onLine || networkState !== 'none') {
            Services.checklistInfo(this, id, params.id);
            this.anexosVisualizar();
        }

        if (!navigator.onLine || networkState === 'none') {
            this.setState({ checklist: checklistInfo.checklist });
            this.setState({ checklistInfo: checklistInfo.checklist.info });
            this.setState({ checklistTarefas: checklistInfo.checklist.tarefas });
            let contador = checklistInfo.checklist.tarefas.length; // calculando o tamanho do array
            this.setState({ size: contador }) //salva em uma variável
            //Services.checklistInfo(this, id, params.id);
        }

        if (networkState === 'none' && checklistInfo === [] || !navigator.onLine && checklistInfo === []) {
            this.setState({ error: "Você não está conectado a internet" });
            this.setState({ open_message: true })
            this.setState({ modal_type: 'error' })
        }

    }

    responseChecklistInfo(response) {
        var params = this.props.match.params;

        this.setState({ checklist: response.checklist });
        this.setState({ checklistInfo: response.checklist.info });
        this.setState({ checklistTarefas: response.checklist.tarefas });

        let contador = this.state.checklistTarefas.length; // calculando o tamanho do array
        this.setState({ size: contador }) //salva em uma variável

        if (parseInt(this.state.count) > parseInt(this.state.size) - 1) {
            this.setState({ openmodal: true })
        }

        window.localStorage.removeItem('checklistInfos-' + params.id);
        localStorage.setItem('checklistInfos-' + params.id, JSON.stringify(response));//atualizando o cache 


        if (this.state.checklist === []) {
            this.setState({ error: "Ocorreu um erro, tente novamente" });
            this.setState({ open_message: true })
            this.setState({ modal_type: 'warning' })
        }
    }

    anexosVisualizar = () => {
        var params = this.props.match.params;

        let id = JSON.parse(localStorage.getItem('id-user')); //limpando o id do usuario salvo na cache

        Services.anexosVisualizar(this, id, params.id);
    }

    responseAnexosVisualizar(response) {

        var params = this.props.match.params;
        this.setState({ anexosArray: response.comentarios_anexos })
        if (this.state.checklistTarefas.length > 0 && this.state.count < this.state.checklistTarefas.length) {
            const tarefa = this.state.checklistTarefas[parseInt(this.state.count)].id

            for (let i = 0; i < this.state.anexosArray.length; i++) {

                if (this.state.anexosArray[i].id_pergunta === tarefa) {
                    this.setState({ textArquivos: "Arquivos" })
                    let image = new Image(300);
                    image.src = this.state.anexosArray[i].anexo_base64;
                    var x = document.createElement("P");
                    var t = document.createTextNode("Arquivo adicionado em: " + this.state.anexosArray[i].data_hora_registro);
                    x.prepend(t);
                    document.getElementById("previewAnexos").prepend(image);
                    document.getElementById("previewAnexos").prepend(x);
                }
            }
        }

    }

    onChangeButton = e => {
        const resposta = e.currentTarget.value;
        if (resposta === undefined || resposta === null || resposta === '') {
            this.setState({ error: "Ops, algo deu errado ao registrar sua resposta, selecione novamente." })
            this.setState({ open_message: true })
            this.setState({ modal_type: 'warning' })
        }
        else {
            if (resposta === '1') {
                document.getElementById('button-1').style.backgroundColor = 'green';
                document.getElementById('button-2').style.backgroundColor = '';
                document.getElementById('button-3').style.backgroundColor = '';
            }
            else if (resposta === '2') {
                document.getElementById('button-1').style.backgroundColor = '';
                document.getElementById('button-2').style.backgroundColor = 'red';
                document.getElementById('button-3').style.backgroundColor = '';

            }
            else if (resposta === '3') {
                document.getElementById('button-1').style.backgroundColor = '';
                document.getElementById('button-2').style.backgroundColor = '';
                document.getElementById('button-3').style.backgroundColor = '#ffc107';
            }
            this.setState({ resposta: resposta });
        }
    }

    onChangeResposta = e => {
        const resposta = e.target.value;
        this.setState({ resposta: resposta });

    }

    onChangeCheckbox = e => {

        // current array of options
        const options = this.state.checkboxList
        let index;

        // check if the check box is checked or unchecked
        if (e.target.checked) {
            // add the numerical value of the checkbox to options array
            options.push(e.target.value)
        } else {
            // or remove the value from the unchecked checkbox from the array
            index = options.indexOf(+e.target.value)
            options.splice(index, 1)
        }

        this.setState({ checkboxList: options })
        this.setState({ resposta: JSON.stringify(this.state.checkboxList).replace(/[^\d, \,]+/g, '') })
        // update the state with the new array of options
    }

    onChangeAnexo = async (e) => {
        // alert (e.target.files[0])

        const arq = await resizeFile(e.target.files[0]);

        localStorage.setItem('anexoTemp', arq);

        // let reader = new FileReader();
        // reader.readAsDataURL(arq);
        // reader.onload = function () {
        //     let emptySpace = Math.round(JSON.stringify(localStorage).length / 1024);
        //     let arqSpace = Math.round(JSON.stringify(reader.result).length / 1024);
        //     let totalSpace = parseInt(emptySpace)+parseInt(arqSpace);

        //     if(parseInt(totalSpace) < 5000){
        //         localStorage.setItem('anexoTemp', reader.result);
        //     } 
        //     else{
        //         return
        //     }
        // }
        // reader.onerror = function (error) {
        //     //window.localStorage.removeItem('imgChamado');
        //     console.log('Error: ', error);
        // };
        document.getElementById('file').value = "";
    }

    onChangeCamera = (event) => {
        const destinationType = navigator.camera.DestinationType;
        event.preventDefault();
        navigator.camera.getPicture(this.onSuccess, this.onFail, {
            quality: 20,
            correctOrientation: true,
            allowEdit: false,
            destinationType: destinationType.DATA_URL,
            targetHeight: 800,
        });

    }

    onSuccess = (imageData) => {
        this.setState({ retorno_midia: "Uma imagem do dispositivo foi capturada!" })

        // let reader = new FileReader();

        // reader.readAsDataURL(JSON.parse(imageData));
        // reader.onload = function () {
        //         localStorage.setItem('anexoTemp', reader.result);
        // }
        // reader.onerror = function (error) {
        //     window.localStorage.removeItem('anexoTemp');
        //     this.setState({ loading: false });
        //     this.setState({ error: "Ops, ocorreu um erro. Tente novamente! "+ error })
        //     this.setState({ open_message: true })
        //     this.setState({ modal_type: 'warning' })
        //     return
        // };

        let picSrc = "data:image/jpeg;base64," + imageData;
        window.localStorage.removeItem('anexoTemp');
        localStorage.setItem('anexoTemp', picSrc);
    }

    handleSubmit = e => {
        this.setState({ loading: true });
        const params = this.props.match.params;
        const id = JSON.parse(localStorage.getItem('id-user'));
        const tarefa = document.getElementById('id-pergunta').value;
        const index = document.getElementById('index').value;
        let arq = window.localStorage.getItem('anexoExists') || [];
        let coment = window.localStorage.getItem('comentExists') || [];

        e.preventDefault();

        let emptySpace = Math.round(JSON.stringify(localStorage).length / 1024);

        if (emptySpace > 4500) {
            this.setState({ error: "Sua memória disponível está acabando, lembre-se de enviar os checklists em aberto!" });
            this.setState({ open_message: true })
            this.setState({ modal_type: 'warning' })
        }

        if (tarefa === undefined || this.state.resposta === '' || this.state.resposta === undefined) {
            this.setState({ loading: false });
            this.setState({ error: "Ops, você não registrou uma resposta. Tente novamente!" })
            this.setState({ open_message: true })
            this.setState({ modal_type: 'warning' })
            return
        }

        if (this.state.checklistTarefas[index].comentario === '1' && coment.length === 0 || this.state.checklistTarefas[index].comentario === 1 && coment.length === 0) {
            this.setState({ loading: false });
            this.setState({ error: "Nesta pergunta os comentários são obrigatórios!" })
            this.setState({ open_message: true })
            this.setState({ modal_type: 'error' })
            return
        }

        if (this.state.checklistTarefas[index].anexo === '1' && arq.length === 0 || this.state.checklistTarefas[index].anexo === 1 && arq.length === 0) {
            this.setState({ loading: false });
            this.setState({ error: "Nesta pergunta os anexos são obrigatórios!" })
            this.setState({ open_message: true })
            this.setState({ modal_type: 'error' })
            return
        }

        if (this.state.checklistTarefas[index].foto === '1' && arq.length === 0 || this.state.checklistTarefas[index].foto === 1 && arq.length === 0) {
            this.setState({ loading: false });
            this.setState({ error: "Nesta pergunta os anexos são obrigatórios!" })
            this.setState({ open_message: true })
            this.setState({ modal_type: 'error' })
            return
        }

        let respostas = JSON.parse(localStorage.getItem('respostas-' + params.id)) || [];
        respostas.push({ id: tarefa, resposta: this.state.resposta });
        localStorage.setItem('respostas-' + params.id, JSON.stringify(respostas)); // Salva cada checklist no local storage referenciado pelo seu array

        window.localStorage.removeItem('anexoExists'); // limpa a variavel que verifica se existe anexo obrigatorio
        window.localStorage.removeItem('comentExists'); // limpa a variavel que verifica se existe comentario obrigatorio
        window.localStorage.removeItem('addAnexo'); // limpa a variavel q verifica se existe qualquer arquivo
        window.localStorage.removeItem('anexoTemp'); // limpa a variavel que guarda temporariamente um arquivo

        this.setState({ comentario: "" })
        this.setState({ resposta: "" })
        this.setState({ loading: false });

        if (parseInt(this.state.count) < parseInt(this.state.size) - 1) {
            this.setState({ count: parseInt(this.state.count) + 1 })
            this.setState({ textArquivos: "" })
        }

        else if (parseInt(this.state.count) >= parseInt(this.state.size) - 1) {
            this.setState({ openmodal: true })
        }
    }

    responseResposta(response) {

        if (response.error) {
            this.setState({ error: response.error.msg })
            this.setState({ open_message: true })
            this.setState({ modal_type: 'error' })
            return
        }
    }

    onChangeComentario = e => {
        this.setState({
            comentario: e.target.value
        });
    }

    handleClose = () => {
        this.setState({ open_message: false })
    }

    handleCloseModal = () => {
        this.setState({ openmodal: false })
    }

    enviarAnexos = e => {
        // value de e é o id da pergunta, params é o id do checklist, o arquivo está salvo em um localStorage.
        let pergunta = e.currentTarget.value;
        let index = parseInt(e.currentTarget.name);
        var params = this.props.match.params;
        let id = JSON.parse(localStorage.getItem('id-user'));
        let arq = window.localStorage.getItem('anexoTemp') || [];
        let anexo_exists = JSON.parse(localStorage.getItem('anexoExists')) || [];
        let coment_exists = JSON.parse(localStorage.getItem('comentExists')) || [];
        let add_anexo = JSON.parse(localStorage.getItem('addAnexo')) || [];

        if (add_anexo.length >= 3) {
            this.setState({ error: "Você inseriu 3 anexos e atingiu o limite de anexos para esta pergunta!" })
            this.setState({ open_message: true })
            this.setState({ modal_type: 'error' })
            return
        }

        if (this.state.checklistTarefas[index].comentario === '1' && this.state.comentario !== '' && arq.length <= 0) {
            coment_exists.push({ text: "ok" });
            localStorage.setItem('comentExists', JSON.stringify(coment_exists)); // variavel criada se existe um comentario obrigatorio
        }

        if (this.state.checklistTarefas[index].comentario === 1 && this.state.comentario !== '' && arq.length <= 0) {
            coment_exists.push({ text: "ok" });
            localStorage.setItem('comentExists', JSON.stringify(coment_exists)); // variavel criada se existe um comentario obrigatorio
        }

        if (this.state.checklistTarefas[index].comentario === 1 && this.state.comentario !== '' && arq.length > 0) {
            coment_exists.push({ text: "ok" });
            localStorage.setItem('comentExists', JSON.stringify(coment_exists)); // variavel criada se existe um comentario obrigatorio
            anexo_exists.push({ text: "ok" });
            localStorage.setItem('anexoExists', JSON.stringify(anexo_exists)); // variavel criada se existe um anexo obrigatorio
        }

        if (this.state.checklistTarefas[index].comentario === '1' && this.state.comentario !== '' && arq.length > 0) {
            coment_exists.push({ text: "ok" });
            localStorage.setItem('comentExists', JSON.stringify(coment_exists)); // variavel criada se existe um comentario obrigatorio
            anexo_exists.push({ text: "ok" });
            localStorage.setItem('anexoExists', JSON.stringify(anexo_exists)); // variavel criada se existe um anexo obrigatorio
        }

        if (this.state.checklistTarefas[index].anexo === '1' && arq.length > 0 ) {
            anexo_exists.push({ text: "ok" });
            localStorage.setItem('anexoExists', JSON.stringify(anexo_exists)); // variavel criada se existe um anexo obrigatorio
        }

        if (this.state.checklistTarefas[index].anexo === 1 && arq.length > 0) {
            anexo_exists.push({ text: "ok" });
            localStorage.setItem('anexoExists', JSON.stringify(anexo_exists));// variavel criada se existe um anexo obrigatorio
        }

        if (this.state.checklistTarefas[index].foto === '1' && arq.length > 0) {
            anexo_exists.push({ text: "ok" });
            localStorage.setItem('anexoExists', JSON.stringify(anexo_exists)); // variavel criada se existe um anexo obrigatorio
        }

        if (this.state.checklistTarefas[index].foto === 1 && arq.length > 0) {
            anexo_exists.push({ text: "ok" });
            localStorage.setItem('anexoExists', JSON.stringify(anexo_exists)); // variavel criada se existe um anexo obrigatorio
        }

        let arquivos = JSON.parse(localStorage.getItem('anexos-' + params.id)) || [];
        arquivos.push({ id: pergunta, comentario: this.state.comentario, anexo: arq });

        let emptySpace = Math.round(JSON.stringify(localStorage).length / 1024);
        let arqSpace = Math.round(JSON.stringify(arquivos).length / 1024);
        let totalSpace = parseInt(emptySpace) + parseInt(arqSpace);

        // alert(emptySpace+","+arqSpace+","+totalSpace);
        // console.log(emptySpace+","+arqSpace+","+totalSpace);

        if (parseInt(totalSpace) <= 5000 && arq !== []) {
            localStorage.setItem('anexos-' + params.id, JSON.stringify(arquivos)); // Salva cada checklist no local storage referenciado pelo seu array

            add_anexo.push({ text: "ok" }); // variavel criada se existe qualquer arquivo
            localStorage.setItem('addAnexo', JSON.stringify(add_anexo));

            let image = new Image(300);
            image.src = window.localStorage.getItem('anexoTemp');
            var x = document.createElement("P");
            var t = document.createTextNode(this.state.comentario);
            x.prepend(t);
            document.getElementById("previewAnexos").prepend(image);
            document.getElementById("previewAnexos").prepend(x);

            window.localStorage.removeItem('anexoTemp');
        }
        if (parseInt(totalSpace) > 5000) {
            let anexos = JSON.parse(localStorage.getItem('anexoExists')) || [];
            let coments = JSON.parse(localStorage.getItem('comentExists')) || [];

            this.setState({ error: "Parece que a memória cache do celular atingiu seu limite! Tente um arquivo menor ou clique no menu lateral em SAIR e preencha novamente o Checklist." })
            this.setState({ open_message: true })
            this.setState({ modal_type: 'warning' })
            window.localStorage.removeItem('anexoTemp');

            if (anexos !== [] && anexos.length === 1) {
                localStorage.removeItem('anexoExists');
            }
            if (coments !== [] && coments.length === 1) {
                localStorage.removeItem('comentExists');
            }
            return
        }

        // try {
        //     localStorage.setItem('anexos-' + params.id, JSON.stringify(arquivos)); // Salva cada checklist no local storage referenciado pelo seu array
        // } catch (e) {
        //     let anexos = JSON.parse(localStorage.getItem('anexoExists')) || [];
        //     let coments = JSON.parse(localStorage.getItem('comentExists')) || [];

        //     this.setState({ error: "Parece que a memória cache do celular atingiu seu limite! Tente um arquivo menor ou clique no menu lateral em SAIR e preencha novamente o Checklist." })
        //     this.setState({ open_message: true })
        //     this.setState({ modal_type: 'warning' })

        //     if (anexos !== [] && anexos.length === 1) {
        //         localStorage.removeItem('anexoExists');
        //     }
        //     if(coments !== [] && coments.length === 1){
        //         localStorage.removeItem('comentExists');
        //     }
        //     return
        // }

    }


    responseComentarios(response) {
        if (response.error === "true") {
            this.setState({ error: "Ocorreu um erro ao anexar o arquivo! Tente novamente " })
            this.setState({ open_message: true })
            this.setState({ modal_type: 'error' })
            return
        }
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    finalizar = e => {
        this.setState({ loadingfin: true });
        const params = this.props.match.params;
        const id = JSON.parse(localStorage.getItem('id-user'));

        if (navigator.appCodeName !== 'Mozilla') {
            var networkState = navigator.connection.type;
        }
        else {
            var networkState = '';
        }

        if (navigator.onLine || networkState !== 'none') {

            let respostasFinal = JSON.parse(localStorage.getItem('respostas-' + params.id)) || [];
            let anexosFinal = JSON.parse(localStorage.getItem('anexos-' + params.id)) || [];

            for (let x = 0; x < respostasFinal.length; x++) {
                Services.respostaOffline(this, id, params.id, respostasFinal[x].id, respostasFinal[x].resposta);
            }

            for (let y = 0; y < anexosFinal.length; y++) {
                Services.comentariosOffline(this, id, params.id, anexosFinal[y].id, anexosFinal[y].comentario, anexosFinal[y].anexo);
            }

            if (e.currentTarget.value === '0') {
                Services.finaliza(this, id, params.id)
            }

            else if (e.currentTarget.value === '1') {
                let formData = new FormData();    //formdata object

                formData.append('login', this.state.cpf);   //append the values with key, value pair
                formData.append('password', this.state.senha);
                //this.setState({ redirect: 'menu' })

                Services.assinatura(this, formData);
                Services.finaliza(this, id, params.id, e.currentTarget.value)
            }
        }

        if (!navigator.onLine || networkState === 'none') {
            this.setState({ loadingfin: false });
            this.setState({ error: "Ops, parece que você não está conectado a internet!" })
            this.setState({ open_message: true })
            this.setState({ modal_type: 'warning' })
        }
    };

    assinaturaResponse(response) {

        if (response.success === 'true') {
            const params = this.props.match.params;
            const id = JSON.parse(localStorage.getItem('id-user'));
            Services.finaliza(this, id, params.id, response.user.id)
        }

        if (response.error === 'true') {
            this.setState({ error: "Ocorreu um erro ao assinar o checklist, tente novamente. " })
            this.setState({ loadingfin: false });
            this.setState({ open_message: true })
            this.setState({ modal_type: 'error' })
        }
    }

    responseFinaliza(response) {
        if (response.success === 'true') {
            const params = this.props.match.params;
            localStorage.removeItem('respostas-' + params.id);
            localStorage.removeItem('anexos-' + params.id);
            this.setState({ redirect: 'categorias' })
            this.setState({ loadingfin: false });
        }

        if (response.error === 'true') {
            this.setState({ error: "Ocorreu um erro ao finalizar o checklist, tente novamente. " })
            this.setState({ loadingfin: false });
            this.setState({ open_message: true })
            this.setState({ modal_type: 'error' })
        }

    }

    render() {

        if (this.state.redirect) {
            return (<Redirect to={'/' + this.state.redirect} />);
        }

        let arq_exists = window.localStorage.getItem('anexoExists') || [];
        let coment_exists = window.localStorage.getItem('comentExists') || [];
        let anexo_exists = window.localStorage.getItem('addAnexo') || [];

        return (<div>
            <AppMenu />
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <main style={{ flexGrow: 1, height: '100vh', overflow: 'auto', marginTop: '-100vh', backgroundColor: '#ececea' }}>

                    <Grid container style={{ marginTop: '10vh' }}>
                        <Grid item xs={3}>
                            <ArrowBack style={{ color: '#666' }} onClick={() => this.setState({ redirect: 'categoria/' + this.state.checklistInfo.id_categoria })} />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography color="textPrimary">{this.state.checklistInfo.titulo}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <span className="tag-categoria" style={{ background: this.state.checklistInfo.categoria_cor }} >
                                {this.state.checklistInfo.categoria}
                            </span>
                        </Grid>
                    </Grid>

                    <List component="nav" aria-label="contacts">
                        {this.state.checklistTarefas.map((checklistTarefas, index) => {
                            return <div key={index} style={{ backgroundColor: '#fff', width: '96%', marginLeft: '2%', marginBottom: 5 }}>

                                <If condition={parseInt(this.state.count) !== index}>
                                </If>

                                <If condition={parseInt(this.state.count) === index}>
                                    <div style={{ visibility: 'visible' }}>

                                        <ListItem>
                                            <ListItemText primary={checklistTarefas.titulo} />
                                        </ListItem>

                                        <form onSubmit={this.handleSubmit}>
                                            <input type="hidden" value={checklistTarefas.id} id='id-pergunta' name="id-pergunta" />
                                            <input type="hidden" value={index} id='index' name="index" />
                                            <If condition={checklistTarefas.tipo === "texto"}>
                                                <TextField onChange={this.onChangeResposta} style={{ marginTop: 10, marginBottom: 5, width: '96%' }} id="outlined-basic" variant="outlined" name="texto-pergunta" />
                                            </If>

                                            <If condition={checklistTarefas.tipo === 'texto_longo'} >
                                                <TextField
                                                    name="texto-longo-pergunta"
                                                    id="outlined-multiline-static"
                                                    multiline
                                                    rows="4"
                                                    style={{ marginTop: 10, marginBottom: 5, width: '96%' }}
                                                    defaultValue=""
                                                    variant="outlined"
                                                    onChange={this.onChangeResposta}
                                                />
                                            </If>

                                            <If condition={checklistTarefas.tipo === 'selecao_lista'}>
                                                <FormGroup style={{ marginLeft: 15 }}>
                                                    {checklistTarefas.opcoes_selecao.map((opcoes, i) => {
                                                        return (<FormControlLabel
                                                            control={<Checkbox
                                                                value={i}
                                                                color='default' key={i} />}
                                                            label={opcoes.titulo}
                                                            name={opcoes.id}
                                                            onChange={this.onChangeCheckbox}
                                                        />
                                                        )
                                                    })}
                                                </FormGroup>
                                            </If>

                                            <If condition={checklistTarefas.tipo === 'selecao_radio'}>
                                                <RadioGroup aria-label="gender" name={checklistTarefas.id} onChange={this.onChangeResposta} style={{ marginLeft: 15 }}>
                                                    {checklistTarefas.opcoes_selecao.map((opcoes, i) => {
                                                        return (
                                                            <FormControlLabel key={i} value={opcoes.id} control={<Radio />} label={opcoes.titulo} />
                                                        )
                                                    })}
                                                </RadioGroup>
                                            </If>

                                            <If condition={checklistTarefas.tipo === 'opcao'}>
                                                <ButtonGroup color="button" variant='contained' aria-label="outlined primary button group" name='button-pergunta' >
                                                    <Button startIcon={<DoneIcon />}
                                                        onClick={this.onChangeButton}
                                                        name="button"
                                                        id="button-1"
                                                        value='1'>Sim</Button>
                                                    <Button startIcon={<CloseIcon />}
                                                        onClick={this.onChangeButton}
                                                        name="button"
                                                        id="button-2"
                                                        value='2'>Não</Button>
                                                    <Button startIcon={<WarningIcon />}
                                                        onClick={this.onChangeButton}
                                                        name="button"
                                                        id="button-3"
                                                        value='3'>Não se aplica</Button>
                                                </ButtonGroup>
                                            </If>

                                            <ListItem>
                                                <ListItemText primary="Anexos" />
                                            </ListItem>

                                            <Grid container spacing={3} label="Anexos" style={{ marginTop: 5 }}>
                                                <Grid item xs={6}>
                                                    <label className='anexo'>
                                                        <CameraIcon fontSize='large' onClick={this.onChangeCamera} />
                                                    </label>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <label for="file" className='anexo'>
                                                        <SaveIcon htmlFor="file" fontSize='large' />
                                                    </label>
                                                    <Input id="file" type="file" onChange={this.onChangeAnexo} style={{ display: 'none' }} />
                                                </Grid>
                                            </Grid>

                                            <If condition={(checklistTarefas.anexo === '1' || checklistTarefas.anexo === 1
                                                || checklistTarefas.foto === '1' || checklistTarefas.anexo === 1) && arq_exists.length <= 0}>
                                                <Alert style={{ marginTop: 10, marginBottom: 10, marginLeft: '2%', width: '96%' }} severity="warning">Nesta pergunta, os anexos são obrigatórios. ANEXE O ARQUIVO E CLIQUE NO BOTÃO "ENVIAR COMENTÁRIO E ARQUIVO".</Alert>
                                            </If>

                                            <If condition={(checklistTarefas.comentario === '1' || checklistTarefas.anexo === 1) && coment_exists.length <= 0}>
                                                <Alert style={{ marginTop: 10, marginBottom: 10, marginLeft: '2%', width: '96%' }} severity="warning">Nesta pergunta, os comentários são obrigatórios. ESCREVA O COMENTÁRIO E CLIQUE NO BOTÃO "ENVIAR COMENTÁRIO E ARQUIVO".</Alert>
                                            </If>

                                            <If condition={anexo_exists.length > 0}>
                                                <Alert style={{ marginTop: 10, marginBottom: 10, marginLeft: '2%', width: '96%' }} severity="info">Anexos ou comentários foram adicionados a essa pergunta.</Alert>
                                            </If>

                                            <If condition={arq_exists.length > 0}>
                                                <Alert style={{ marginTop: 10, marginBottom: 10, marginLeft: '2%', width: '96%' }} severity="info">VOCÊ JÁ ADICIONOU UM ANEXO OBRIGATÓRIO PARA ESTA PERGUNTA.</Alert>
                                            </If>

                                            <If condition={coment_exists.length > 0}>
                                                <Alert style={{ marginTop: 10, marginBottom: 10, marginLeft: '2%', width: '96%' }} severity="info">VOCÊ JÁ ADICIONOU UM COMENTÁRIO OBRIGATÓRIO PARA ESTA PERGUNTA.</Alert>
                                            </If>

                                            <ListItem>
                                                <ListItemText primary="Comentários" onChange={this.onChangeComentario} />
                                            </ListItem>

                                            <TextField
                                                id="outlined-multiline-static"
                                                multiline
                                                rows="4"
                                                style={{ marginTop: 10, marginBottom: 5, width: '96%' }}
                                                defaultValue=""
                                                variant="outlined"
                                                onChange={this.onChangeComentario}
                                            />

                                            <Button
                                                size="large"
                                                color="secondary"
                                                variant="contained"
                                                style={{ marginTop: 5, marginBottom: 15, width: '96%' }}
                                                value={checklistTarefas.id}
                                                name={index}
                                                onClick={this.enviarAnexos}>
                                                Enviar comentário e arquivo
                                            </Button>

                                            <ListItem>
                                                <ListItemText primary={this.state.textArquivos} />
                                            </ListItem>

                                            <div className='previewAnexosDiv' >
                                                <div id='previewAnexos' className='previewAnexos' >
                                                </div>
                                            </div>

                                            <If condition={this.state.endButton === "next"}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    style={{ marginTop: 5, marginBottom: 15, width: '96%' }}
                                                    name={index}
                                                    disabled={this.state.loading}
                                                >
                                                    Próximo
                                                </Button>
                                            </If>

                                        </form>


                                    </div>
                                </If>

                                <Dialog open={this.state.openmodal} onClose={this.handleCloseModal} aria-labelledby="form-dialog-title">

                                    <DialogTitle id="form-dialog-title">Assinatura</DialogTitle>

                                    <If condition={this.state.checklistInfo.assinatura === '1' || this.state.checklistInfo.assinatura === 1}>
                                        <DialogContent>
                                            <TextField
                                                autoFocus
                                                margin="dense"
                                                id="cpf"
                                                name='cpf'
                                                label="CPF"
                                                type="tel"
                                                fullWidth
                                                onChange={e => this.handleChange(e)}
                                                required
                                            />
                                            <TextField
                                                autoFocus
                                                margin="dense"
                                                id="senha"
                                                name='senha'
                                                label="Senha"
                                                type="tel"
                                                fullWidth
                                                onChange={e => this.handleChange(e)}
                                                required
                                            />
                                        </DialogContent>
                                    </If>

                                    <DialogContent>
                                        <Button
                                            color="primary"
                                            value={this.state.checklistInfo.assinatura}
                                            onClick={this.finalizar}
                                            disabled={this.state.loadingfin}>

                                            Finalizar Checklist
                            </Button>
                                    </DialogContent>
                                </Dialog>

                            </div>

                        })}
                    </List>

                    <Snackbar open={this.state.open_message} onClose={this.handleClose}>
                        <MuiAlert elevation={6} variant="filled" onClose={this.handleClose} severity={this.state.modal_type}>
                            {this.state.error}
                        </MuiAlert>
                    </Snackbar>

                </main>
            </ThemeProvider>
        </div >);

    }
}
