import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
const startApp = () => {
    try{
    ReactDOM.render(<App />, document.getElementById('root'));
    }catch(e){
      alert(e)  
    }
}
if(window.cordova) {
    document.addEventListener('deviceready', startApp, false);
} else {
    startApp();
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
