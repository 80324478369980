import React from 'react';
import Services from "../Services";
import { Link } from 'react-router-dom'
import AppMenu from '../components/Menu'
import { Redirect } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import RefreshRounded from '@material-ui/icons/RestorePageRounded';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PlusIcon from '@material-ui/icons/AddCircle'
import ListItemText from '@material-ui/core/ListItemText';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

export default class Categorias extends React.Component {

    state = {
        cpf: '',
        senha: '',
        redirect: '',
        error: '',
        open_message: false,
        categorias: [],
        modal_type: '',
        network: '',
    }

    componentDidMount() {

        if (navigator.appCodeName !== 'Mozilla'){
        var networkState = navigator.connection.type;
        }
        else{
            var networkState = '';
        }

        let user = JSON.parse(localStorage.getItem('nome-user'));
        let id = JSON.parse(localStorage.getItem('id-user')); 
        let categ = JSON.parse(localStorage.getItem('categorias')) || [];

        if (navigator.onLine || networkState !== 'none') {
            Services.categorias(this, id);
        }

        if (!navigator.onLine || networkState === 'none') {
            this.setState({ categorias: JSON.parse(localStorage.getItem('categorias')) });
        }

        if (networkState === 'none' && categ === [] || !navigator.onLine && categ === []) {
            this.setState({ error: "Você não está conectado a internet" });
            this.setState({ open_message: true })
            this.setState({ modal_type: 'error' })
        }
    }

    responseCategorias(response) {
        this.setState({ categorias: response.categorias });
        window.localStorage.removeItem('categorias');
        localStorage.setItem('categorias', JSON.stringify(response.categorias));
        if (this.state.categorias[0] === [] || this.state.categorias[0] === '' || this.state.categorias[0] === undefined || this.state.categorias[0] === null) {
            this.setState({ error: "Não há checklists cadastrados no momento" });
            this.setState({ open_message: true })
            this.setState({ modal_type: 'warning' })
        }
    }

    handleClose = () => {
        this.setState({ open_message: false })
    }


    render() {

        if (this.state.redirect) {
            return (<Redirect to={'/' + this.state.redirect} />);
        }

        return (<div>
            <AppMenu />

            <main style={{ flexGrow: 1, height: '100vh', overflow: 'auto', marginTop: '-100vh', backgroundColor: '#ececea' }}>
                <Grid container style={{ marginTop: '10vh' }}>
                    <Grid item xs={3}>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography color="textPrimary">Categorias</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <RefreshRounded style={{color: '#666'}} onClick={() => window.location.reload()}/>
                    </Grid>
                </Grid>

                <List component="nav" aria-label="contacts">
                    {this.state.categorias.map((categorias) => {
                        return <Link to={"/categoria/" + categorias.id} style={{ textDecoration: 'none' }} >
                            <ListItem style={{
                                borderRadius: 7,
                                color: '#fff',
                                position: 'relative',
                                marginBottom: 5,
                                background: categorias.cor,
                                width: '96%',
                                marginLeft: '2%',
                            }}>
                                <ListItemText primary={categorias.titulo} />
                                <ListItemText style={{ textAlign: 'right'}} primary={"Tarefas: " + categorias.total_checklists + " "} />
                                <ListItemIcon>
                                    <PlusIcon style={{ color: '#fff', textAlign: 'right', marginLeft:20 }} fontSize='medium'/>
                                </ListItemIcon>
                            </ListItem>
                        </Link>
                    })}
                </List>

                <Snackbar open={this.state.open_message} autoHideDuration={5000} onClose={this.handleClose}>
                    <MuiAlert elevation={6} variant="filled" onClose={this.handleClose} severity={this.state.modal_type}>
                        {this.state.error}
                    </MuiAlert>
                </Snackbar>

            </main>

        </div>);
    }

}