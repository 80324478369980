import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import ReactTimeout from 'react-timeout'
import Splash from '../assets/splashscreen.gif'

class SplashScreen extends Component {
    state = {
        redirect: false,
    }
    componentDidMount() {
        this.props.setTimeout(this.router, 5000) 
    }

    router = () =>{
        this.setState({ redirect: 'login' });
    }

    render() {
        if (this.state.redirect) {
            return (<Redirect to={'/' + this.state.redirect} />);
        }
        return <div className='viewSplash'>
            <img className='img-splash' src={Splash} />
        </div>
    }
}

export default ReactTimeout(SplashScreen);